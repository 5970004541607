(function ($) {
  Drupal.behaviors.localeInterstitial = {
    attach: function (context, settings) {
      var prefCountry, prefLanguage;
      var prefLink, defaultCountryLang, defaultLink;
      var cookieName = 'POPUP_INTERSTITIAL';

      // do not show on access denied page
      if ($('body').hasClass('content-gate-access-denied-page')) {
        return;
      }

      $(document).ready(function () {
        var hasLocaleIntCookie = hasCookieCurrentLocale();

        if (!hasLocaleIntCookie) {
          displayLocaleInterstitial();
          $('.block-template-country-picker-v1').hide();
        }
      });

      $(document).on('displayLocaleInterstitial', function () {
        displayLocaleInterstitial();
      });

      function hasCookieCurrentLocale() {
        var localeName = $.cookie('LOCALE') ? $.cookie('LOCALE').toUpperCase() : '';
        var valueCookieForCurrentLocale = 'POPUP_INTERSTITIAL_' + localeName;
        var hasLocaleIntCookie = 0;

        if ($.cookie(cookieName) !== undefined && $.cookie(cookieName) !== null) {
          if ($.cookie(cookieName) === valueCookieForCurrentLocale) {
            hasLocaleIntCookie = 1;
          } else {
            hasLocaleIntCookie = 0;
          }
        }

        return hasLocaleIntCookie;
      }

      function displayLocaleInterstitial() {
        generic.jsonrpc.fetch({
          method: 'locale.detect',
          params: [],
          onSuccess: function (jsonRpcResponse) {
            setOverlayLocale(jsonRpcResponse.getValue());
          },
          onFailure: function (jsonRpcResponse) {
            setOverlayLocale();
          }
        });
      }

      function formatLocaleOptions() {
        $('.locate-interstitial-overlay li a').each(function () {
          var flag = $(this).find('img').remove()[0];
          var text = $(this).html();
          var country_name = 'country_name' + $(this).data('country');

          $(this).empty();
          $(this).append('<input type="radio" id=' + country_name + '>');
          $(this).append(text).find('label').attr('for', country_name);
          $(this).append(flag);
          $(this).click(function (event) {
            event.preventDefault();
          });
        });
      }

      function locateSelectCountryOption() {
        var localeInsterstitialOverlay = $('.locate-interstitial-overlay');

        localeInsterstitialOverlay.on('click', 'a.country-link', function (e) {
          $('.country-list').find('input[type=radio]').prop('checked', false);
          $(this).find('input[type=radio]').prop('checked', true);

          prefCountry = $(this).data('country').toUpperCase();
          prefLink = $(this).attr('href');
          $('a.country-link').removeClass('selected');
          $(this).addClass('selected');
          if (prefLink !== '#') {
            $('.language-list li').removeClass('selected');
            var countryLangs = $(this).data('languages').split(',');

            if (!prefLanguage || countryLangs.indexOf(prefLanguage) === -1) {
              prefLanguage = countryLangs[0];
              defaultCountryLang = prefLanguage;
            }
            localeInsterstitialOverlay.find('.language-list').html(getLangsFromCountryLink($(this)).join(''));
            localeInsterstitialOverlay.find('.language-list li').removeClass('selected current-lang');

            localeInsterstitialOverlay.find('.language-list li').each(function () {
              if ($(this).data('language') === prefLanguage) {
                $(this).trigger('click');
              }
            });
            if (localeInsterstitialOverlay.find('.language-list li').length <= 1) {
              localeInsterstitialOverlay.find('.initial-hide').hide();
            } else {
              localeInsterstitialOverlay.find('.initial-hide').show();
            }
          } else {
            localeInsterstitialOverlay.find('.language-list').html('');
            localeInsterstitialOverlay.find('.initial-hide').hide();
          }
          var $cbox = $('#colorbox.locate-interstitial-overlay');

          $cbox.colorbox.resize();
          localeInsterstitialOverlay.find('#chooseLocaleButton').prop('disabled', false);
        });

        localeInsterstitialOverlay.on('click', '.language-list li', function (e) {
          $('.language-list').find('input[type=radio]').prop('checked', false);
          $(this).find('input[type=radio]').prop('checked', true);

          prefLanguage = $(this).data('language');
          defaultLink = $(this).data('url-lang');
          $('.language-list li').removeClass('selected current-lang');
          $(this).addClass('selected');
          localeInsterstitialOverlay.find('.selected-lang').html($(this).html());

          if (prefCountry && prefLanguage) {
            localeInsterstitialOverlay.find('#chooseLocaleButton').prop('disabled', false); // enable the choose locale button once both locale variables are set
          } else {
            localeInsterstitialOverlay.find('#chooseLocaleButton').prop('disabled', true); // disable the choose locale button unless both locale variables are set
          }
        });

        localeInsterstitialOverlay.on('click', '#chooseLocaleButton', function () {
          var loc_interst_link;

          if (prefLink !== '#') {
            if (defaultLink.length > 0) {
              loc_interst_link = defaultLink;
            } else {
              if (defaultCountryLang === prefLanguage) {
                loc_interst_link = prefLink;
              } else {
                loc_interst_link = prefLink + '?LOCALE=' + prefLanguage + '_' + prefCountry;
              }
            }
            window.location.href = loc_interst_link;
          }
          generic.overlay.hide();
        });
      }

      function setupCookieInterstitialNoThanks() {
        var localeName = $.cookie('LOCALE') ? $.cookie('LOCALE').toUpperCase() : '';
        var cookieValue = 'POPUP_INTERSTITIAL_' + localeName;

        $.cookie(cookieName, cookieValue, {
          expires: 14,
          path: '/'
        });
        updateCookieEmailSignupOverlay();
      }

      function updateCookieEmailSignupOverlay() {
        $.cookie('pc_email_optin', null, { path: '/' });
      }

      function getLangsFromCountryLink($link) {
        var language_list = new Array();
        var data_url_lang = '';
        var language_abbrs = $link.data('languages').split(',');
        var language_names = $link.data('languagenames').split(',');
        var default_links = $link.data('country-urls').split(',');

        for (var i = 0; i < language_abbrs.length; i++) {
          data_url_lang = default_links[i] ? default_links[i] : '';
          language_list.push(
            '<li data-language="' +
              language_abbrs[i] +
              '" data-url-lang="' +
              data_url_lang +
              '"><input type="radio" name="group-lang" id="li-lang-' +
              language_names[i] +
              '"><label for="li-lang-' +
              language_names[i] +
              '">' +
              language_names[i] +
              '</label></li>'
          );
        }

        for (var i = 0; i < language_list.length; i++) {
          if (language_list[i].substr('data-language="en"') && i > 0) {
            language_list.splice(0, 0, language_list.splice(i, 1));
          }
        }

        return language_list;
      }

      function setOverlayLocale(overlayLocale) {
        if (!overlayLocale) {
          return;
        }
        var country_from_ip = overlayLocale.country_from_ip;
        var siteLocale = overlayLocale.locale.split('_');
        var isSameCountry = country_from_ip === siteLocale[1] ? 1 : 0;
        var europeanCountryList = $('#localeOverlay').find('input.eu_countries_list').val().toUpperCase();
        var isEuropeanCountries = europeanCountryList.indexOf(country_from_ip) !== -1 ? 1 : 0;
        var isSameCountryLocale =
          !isSameCountry && isEuropeanCountries && overlayLocale.locale === 'en_FR'
            ? 1
            : isSameCountry && isEuropeanCountries && overlayLocale.locale === 'en_FR'
              ? 0
              : isSameCountry;

        if (isSameCountryLocale) {
          return;
        }

        var prefLanguage = siteLocale[0].toLowerCase();
        var prefCountry = siteLocale[1].toLowerCase();

        if (prefCountry != '') {
          $('#localeInterstitial .country-list a.country-link').each(function (e) {
            if ($(this).data('country') === prefCountry) {
              $(this).trigger('click');
            }
          });
        }

        if (prefLanguage != '') {
          $('#localeInterstitial .language-list li').each(function (e) {
            if ($(this).data('language') === prefLanguage) {
              $(this).trigger('click');
            }
          });
        }
        setTimeout(function () {
          generic.overlay.launch({
            content: $('#localeOverlay').html(),
            width: 800,
            maxWidth: '100%',
            height: 'auto',
            cssClass: 'locate-interstitial-overlay',
            trapFocus: false,
            onComplete: function () {
              formatLocaleOptions();
              locateSelectCountryOption();
              setupCookieInterstitialNoThanks();

              var $cbox = $('#colorbox.locate-interstitial-overlay');
              var resizeFn = _.debounce(function () {
                $.colorbox.resize();
              }, 400);

              $.colorbox.resize();
              resizeFn();
              $(window).on('resize.interstitial', resizeFn);
            }
          });
        }, 3200);
      }
    }
  };
})(jQuery);
